import React from 'react';
import './App.scss';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Form from "./Page/Form/Form";

const lodash = require('lodash/fp')

function App() {
  return (
    <div className={'App'}>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <header className={'bg-gradient-to-br from-purple-600 to-blue-500'}>
        <div>Invoice generator</div>
      </header>
      <Form/>
    </div>
  )
}

export default App;
