import React, {Fragment, useState} from 'react';
import axios from "axios";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {downloadFromUrl} from "../../Utils/DownloaderUtils";
import {LineSeparator} from '../../Components/LineSeparator/LineSeparator';
import {FormPart} from "../../Components/FormPart/FormPart";
import {InputType} from "../../Components/FormPart/Fields/InputType";
import {SelectType} from "../../Components/FormPart/Fields/SelectType";
import {CheckboxType} from "../../Components/FormPart/Fields/CheckboxType";
import {ErrorMsg} from '../../Utils/ErrorMsg';
import styles from './Form.module.scss';

const lodash = require('lodash/fp')


function Form() {
  const [form, setForm] = useState(
    {
      assure: {
        matricule: "",
        nom: "",
        prenom: "",
        adresse: ""
      },
      factureInformations: {
        numero: "",
        date: ""
      },
      malade: {
        nom: "",
        prenom: "",
        dateNaissance: "",
        lienAssure: ""
      },
      accidentTravail: {
        status: false,
        date: ""
      },
      transportMultiple: false,
      aller: {
        dateTransport: "",
        heureDepart: "",
        heureArrive: "",
        lieuPriseEnCharge: "",
        nbKm: 0,
        numeroMineralogique: "",
        membreDeLequipage: "",
        lieuArrive: ""
      },
      retour: {
        dateTransport: "",
        heureDepart: "",
        heureArrive: "",
        lieuPriseEnCharge: "",
        nbKm: 0,
        numeroMineralogique: "",
        membreDeLequipage: "",
        lieuArrive: ""
      },
      facture: {
        forfait: {
          type: "departemental",
          quantite: 0,
          prix: 0
        },
        majorationDeNuitForfait: {
          quantite: 0,
          prix: 0
        },
        majorationSamediForfait: {
          quantite: 0,
          prix: 0
        },
        tarifKilometrique: {
          quantite: 0,
          prix: 0
        },
        majorationDeNuitKilometrique: {
          quantite: 0,
          prix: 0
        },
        majorationSamediKilometrique: {
          quantite: 0,
          prix: 0
        },
        valorisationTrajetCourt: {
          quantite: 0,
          prix: 0
        },
        valorisationTrajetCourtDeNuit: {
          quantite: 0,
          prix: 0
        },
        valorisationTrajetCourtSamedi: {
          quantite: 0,
          prix: 0
        },
        peage: {
          quantite: 0,
          prix: 0
        },
        supplement: {
          type: '',
          quantite: 0,
          prix: 0
        },
        abbatemmentTransportPartage: {
          type: '0',
          quantite: 0,
          prix: 0
        }
      },
      frais: "totale"
    }
  )

  const [type, setType] = useState('facture');
  const [error, setError] = useState({
    error: {},
    code: '',
    message: ''
  })

  const handleChange = async (event: any) => {
    const target = event.target;

    if (target.type === 'number') {
      if (target.step === '.01') {
        setForm(lodash.set(target.name, parseFloat(target.value), form));
        return;
      }
      setForm(lodash.set(target.name, parseInt(target.value), form));
      return;
    }
    setForm(lodash.set(target.name, target.value, form));
  }

  const handleCheckboxChanges = async (event: any) => {
    const target = event.target;

    if (!target.checked) {
      setForm(lodash.set(target.name, false, form));
    }
    setForm(lodash.set(target.name, target.checked, form));
  }

  const handleType = async (event: any) => {
    const target = event.target;

    await setType(target.value);
  }

  const downloadFileFromForm = async () => {
    try {
      toast.loading("Génération du pdf");
      let response = await axios.post(process.env.REACT_APP_API_URL + '/' + type + '/generate', form, {
        responseType: 'blob'
      })
      downloadFromUrl(response.data, type + form.factureInformations.numero + '.pdf');
      toast.dismiss();
      toast.success('Fichier pdf généré')
    } catch (exception: any) {
      await setError(JSON.parse(await exception.response.data.text()));
      let errorArray: [string] = [''];
      console.log(error);
      for (const [key, value] of Object.entries(JSON.parse(await exception.response.data.text()).error)) {
        errorArray.push(`${key}: ${value}`);
      }
      toast.dismiss();
      toast.error(<ErrorMsg errors={errorArray}/>)
    }
  }

  return (
    <Fragment>
      <div className={styles.buttonWrap}>
        <button className={`${type === 'facture' ?  styles.active : ''} ${styles.button}`} value={'facture'} onClick={handleType}>Facture</button>
        <button className={`${type === 'devis' ?  styles.active : ''} ${styles.button}`} value={'devis'} onClick={handleType}>Devis</button>
      </div>

      <div className={'max-w-screen-xl mr-auto ml-auto px-10'}>
        <LineSeparator/>
        <FormPart formName={"Informations facture"}
                  formDescription={"Les infos de la facture"}
                  children={
                    [
                      <InputType label={'Numéro de la facture'}
                                 inputType={'text'}
                                 inputValue={form.factureInformations.numero}
                                 name={'factureInformations.numero'}
                                 onChangeFunction={handleChange}
                                 key={'factureInformations.numero'}
                      />,
                      <InputType label={'Date de la facture'}
                                 inputType={'date'}
                                 inputValue={form.factureInformations.date}
                                 name={'factureInformations.date'}
                                 onChangeFunction={handleChange}
                                 key={'factureInformations.date'}
                      />
                    ]
                  }/>
        <LineSeparator/>
        <FormPart formName={"Informations de l'assuré"}
                  formDescription={"Les infos de l'assuré"}
                  children={[
                    <InputType label={'Matricule'}
                               inputType={'text'}
                               inputValue={form.assure.matricule}
                               name={'assure.matricule'}
                               onChangeFunction={handleChange}
                               key={'assure.matricule'}
                    />,
                    <InputType label={'Nom'}
                               inputType={'text'}
                               inputValue={form.assure.nom}
                               name={"assure.nom"}
                               onChangeFunction={handleChange}
                               key={'assure.nom'}
                    />,
                    <InputType label={'Prénom'}
                               inputType={'text'}
                               inputValue={form.assure.prenom}
                               name={'assure.prenom'}
                               onChangeFunction={handleChange}
                               key={'assure.prenom'}
                    />,
                    <InputType label={'Adresse'}
                               inputType={'text'}
                               inputValue={form.assure.adresse}
                               name={"assure.adresse"}
                               onChangeFunction={handleChange}
                               key={'assure.adresse'}
                    />
                  ]}
        />
        <LineSeparator/>
        <FormPart formName={"Informations sur le malade"}
                  formDescription={"Les infos de la personne malade"}
                  children={[
                    <InputType label={'Nom'}
                               inputType={'text'}
                               inputValue={form.malade.nom}
                               name={'malade.nom'}
                               onChangeFunction={handleChange}
                               key={'malade.nom'}
                    />,
                    <InputType label={'Prenom'}
                               inputType={'text'}
                               inputValue={form.malade.prenom}
                               name={'malade.prenom'}
                               onChangeFunction={handleChange}
                               key={'malade.prenom'}
                    />,
                    <InputType label={'Date de naissance'}
                               inputType={'date'}
                               inputValue={form.malade.dateNaissance}
                               name={'malade.dateNaissance'}
                               onChangeFunction={handleChange}
                               key={'malade.dateNaissance'}
                    />,
                    <SelectType label={'Lien avec l\'assuré'}
                                selectValue={form.malade.lienAssure}
                                name={'malade.lienAssure'}
                                options={[
                                  {key: '', value: 'Pas de lien'},
                                  {key: 'Enfant', value: 'Enfant'},
                                  {key: 'Conjoint', value: 'Conjoint'}
                                ]}
                                onChangeFunction={handleChange}
                                key={'malade.lienAssure'}
                    />
                  ]}
        />
        <LineSeparator/>
        <FormPart formName={'Accident de travail'}
                  formDescription={'Check si c\'est un accident de travail'}
                  children={[
                    <CheckboxType checked={form.accidentTravail.status}
                                  name={'accidentTravail.status'}
                                  onChangeFunction={handleCheckboxChanges}
                                  label={'Est-ce un accident de travail ?'}
                                  key={'accidentTravail.status'}
                    />,
                    <InputType label={'Si oui, remplir la date de l\'accident:'}
                               inputType={'date'}
                               inputValue={form.accidentTravail.date}
                               name={'accidentTravail.date'}
                               onChangeFunction={handleChange}
                               key={'accidentTravail.date'}
                    />
                  ]}/>
        <LineSeparator/>

        <FormPart formName={'Transports multiples'}
                  formDescription={''}
                  children={[
                    <CheckboxType checked={form.transportMultiple}
                                  name={'transportMultiple'}
                                  onChangeFunction={handleCheckboxChanges}
                                  label={'Est-ce un transport multiple ?'}
                                  key={'transportMultiple'}/>
                  ]}/>
        < LineSeparator/>
        <FormPart formName={'Informations sur le trajet aller'}
                  formDescription={'Toutes les infos du trajet a l\'aller'}
                  children={[
                    <InputType label={'Date de transport'}
                               inputType={'date'}
                               inputValue={form.aller.dateTransport}
                               name={'aller.dateTransport'}
                               onChangeFunction={handleChange}
                               key={'aller.dateTransport'}
                    />,
                    <InputType label={'Heure de départ'}
                               inputType={'text'}
                               inputValue={form.aller.heureDepart}
                               name={'aller.heureDepart'}
                               key={'aller.heureDepart'}
                               onChangeFunction={handleChange}/>,
                    <InputType label={'Heure d\'arrivée'}
                               inputType={'text'}
                               inputValue={form.aller.heureArrive}
                               name={'aller.heureArrive'}
                               key={'aller.heureArrive'}
                               onChangeFunction={handleChange}/>,
                    <InputType label={'Lieu de prise en charge'}
                               inputType={'text'}
                               inputValue={form.aller.lieuPriseEnCharge}
                               name={'aller.lieuPriseEnCharge'}
                               key={'aller.lieuPriseEnCharge'}
                               onChangeFunction={handleChange}/>,
                    <InputType label={'Nombre de kilométres'}
                               inputType={'text'}
                               inputValue={form.aller.nbKm}
                               name={'aller.nbKm'}
                               key={'aller.nbKm'}
                               onChangeFunction={handleChange}/>,
                    <InputType label={'Lieu d\'arrivé'}
                               inputType={'text'}
                               inputValue={form.aller.lieuArrive}
                               name={'aller.lieuArrive'}
                               key={'aller.lieuArrive'}
                               onChangeFunction={handleChange}/>,
                    <InputType label={'Numéro minéralogique'}
                               inputType={'text'}
                               inputValue={form.aller.numeroMineralogique}
                               name={'aller.numeroMineralogique'}
                               key={'aller.numeroMineralogique'}
                               onChangeFunction={handleChange}/>,
                    <InputType label={'Membre de l\'équipage'}
                               inputType={'text'}
                               inputValue={form.aller.membreDeLequipage}
                               name={'aller.membreDeLequipage'}
                               key={'aller.membreDeLequipage'}
                               onChangeFunction={handleChange}/>
                  ]}/>
        <LineSeparator/>
        <FormPart formName={'Informations sur le trajet retour'}
                  formDescription={'Toutes les infos du trajet au retour'}
                  children={[
                    <InputType label={'Date de transport'}
                               inputType={'date'}
                               inputValue={form.retour.dateTransport}
                               name={'retour.dateTransport'}
                               onChangeFunction={handleChange}
                               key={'retour.dateTransport'}
                    />,
                    <InputType label={'Heure de départ'}
                               inputType={'text'}
                               inputValue={form.retour.heureDepart}
                               name={'retour.heureDepart'}
                               key={'retour.heureDepart'}
                               onChangeFunction={handleChange}/>,
                    <InputType label={'Heure d\'arrivée'}
                               inputType={'text'}
                               inputValue={form.retour.heureArrive}
                               name={'retour.heureArrive'}
                               key={'retour.heureArrive'}
                               onChangeFunction={handleChange}/>,
                    <InputType label={'Lieu de prise en charge'}
                               inputType={'text'}
                               inputValue={form.retour.lieuPriseEnCharge}
                               name={'retour.lieuPriseEnCharge'}
                               key={'retour.lieuPriseEnCharge'}
                               onChangeFunction={handleChange}/>,
                    <InputType label={'Nombre de kilométres'}
                               inputType={'text'}
                               inputValue={form.retour.nbKm}
                               name={'retour.nbKm'}
                               key={'retour.nbKm'}
                               onChangeFunction={handleChange}/>,
                    <InputType label={'Lieu d\'arrivé'}
                               inputType={'text'}
                               inputValue={form.retour.lieuArrive}
                               name={'retour.lieuArrive'}
                               key={'retour.lieuArrive'}
                               onChangeFunction={handleChange}/>,
                    <InputType label={'Numéro minéralogique'}
                               inputType={'text'}
                               inputValue={form.retour.numeroMineralogique}
                               name={'retour.numeroMineralogique'}
                               key={'retour.numeroMineralogique'}
                               onChangeFunction={handleChange}/>,
                    <InputType label={'Membre de l\'équipage'}
                               inputType={'text'}
                               inputValue={form.retour.membreDeLequipage}
                               name={'retour.membreDeLequipage'}
                               key={'retour.membreDeLequipage'}
                               onChangeFunction={handleChange}/>
                  ]}/>
        <LineSeparator/>
        <FormPart formName={'Facturation: Forfait'}
                  formDescription={'Facturation partie forfait'}
                  children={[
                    <InputType label={'Quantité'}
                               inputType={'number'}
                               inputValue={form.facture.forfait.quantite}
                               name={'facture.forfait.quantite'}
                               onChangeFunction={handleChange}
                               key={'facture.forfait.quantite'}
                    />,
                    <InputType label={'Prix'}
                               inputType={'number'}
                               inputValue={form.facture.forfait.prix}
                               name={'facture.forfait.prix'}
                               onChangeFunction={handleChange}
                               step={'.01'}
                               key={'facture.forfait.prix'}
                    />,
                    <SelectType label={'Type'}
                                selectValue={form.facture.forfait.type}
                                name={'facture.forfait.type'}
                                key={'facture.forfait.type'}
                                options={[
                                  {
                                    key: 'departemental',
                                    value: 'Départemental'
                                  }, {
                                    key: 'agglo',
                                    value: 'Agglomération'
                                  }, {
                                    key: 'pec',
                                    value: 'PEC'
                                  },
                                ]}
                                onChangeFunction={handleChange}
                    />
                  ]}/>
        <LineSeparator/>
        <FormPart formName={'Facturation: Majoration de nuit'}
                  formDescription={'Facturation partie majoration de nuit'}
                  children={[
                    <InputType label={'Quantité'}
                               inputType={'number'}
                               inputValue={form.facture.majorationDeNuitForfait.quantite}
                               name={'facture.majorationDeNuitForfait.quantite'}
                               onChangeFunction={handleChange}
                               key={'facture.majorationDeNuitForfait.quantite'}/>,
                    <InputType label={'Prix'}
                               inputType={'number'}
                               inputValue={form.facture.majorationDeNuitForfait.prix}
                               name={'facture.majorationDeNuitForfait.prix'}
                               onChangeFunction={handleChange}
                               step={'.01'}
                               key={'facture.majorationDeNuitForfait.prix'}
                    />
                  ]}/>
        <LineSeparator/>
        <FormPart formName={'Facturation: Majoration samedi (à partir de 12h) dimanche/jour férié'}
                  formDescription={'Facturation partie majoration samedi (à partir de 12h) dimanche/jour férié'}
                  children={[
                    <InputType label={'Quantité'}
                               inputType={'number'}
                               inputValue={form.facture.majorationSamediForfait.quantite}
                               name={'facture.majorationSamediForfait.quantite'}
                               onChangeFunction={handleChange}
                               key={'facture.majorationSamediForfait.quantite'}/>,
                    <InputType label={'Prix'}
                               inputType={'number'}
                               inputValue={form.facture.majorationSamediForfait.prix}
                               name={'facture.majorationSamediForfait.prix'}
                               onChangeFunction={handleChange}
                               step={'.01'}
                               key={'facture.majorationSamediForfait.prix'}
                    />
                  ]}/>
        <LineSeparator/>

        <FormPart formName={'Facturation: Tarif kilométrique'}
                  formDescription={'Facturation partie tarif kilométrique'}
                  children={[
                    <InputType label={'Quantité'}
                               inputType={'number'}
                               inputValue={form.facture.tarifKilometrique.quantite}
                               name={'facture.tarifKilometrique.quantite'}
                               onChangeFunction={handleChange}
                               key={'facture.tarifKilometrique.quantite'}/>,
                    <InputType label={'Prix'}
                               inputType={'number'}
                               inputValue={form.facture.tarifKilometrique.prix}
                               name={'facture.tarifKilometrique.prix'}
                               onChangeFunction={handleChange}
                               step={'.01'}
                               key={'facture.tarifKilometrique.prix'}
                    />
                  ]}/>
        <LineSeparator/>

        <FormPart formName={'Facturation: Tarif kilométrique majoration de nuit'}
                  formDescription={'Facturation partie tarif kilométrique majoration de nuit'}
                  children={[
                    <InputType label={'Quantité'}
                               inputType={'number'}
                               inputValue={form.facture.majorationDeNuitKilometrique.quantite}
                               name={'facture.majorationDeNuitKilometrique.quantite'}
                               onChangeFunction={handleChange}
                               key={'facture.majorationDeNuitKilometrique.quantite'}/>,
                    <InputType label={'Prix'}
                               inputType={'number'}
                               inputValue={form.facture.majorationDeNuitKilometrique.prix}
                               name={'facture.majorationDeNuitKilometrique.prix'}
                               onChangeFunction={handleChange}
                               step={'.01'}
                               key={'facture.majorationDeNuitKilometrique.prix'}
                    />
                  ]}/>
        <LineSeparator/>
        <FormPart formName={'Facturation: Tarif kilométrique majoration samedi (à partir de 12h) dimanche/jour férié'}
                  formDescription={'Facturation partie tarif kilométrique majoration samedi (à partir de 12h) dimanche/jour férié'}
                  children={[
                    <InputType label={'Quantité'}
                               inputType={'number'}
                               inputValue={form.facture.majorationSamediKilometrique.quantite}
                               name={'facture.majorationSamediKilometrique.quantite'}
                               onChangeFunction={handleChange}
                               key={'facture.majorationSamediKilometrique.quantite'}/>,
                    <InputType label={'Prix'}
                               inputType={'number'}
                               inputValue={form.facture.majorationSamediKilometrique.prix}
                               name={'facture.majorationSamediKilometrique.prix'}
                               onChangeFunction={handleChange}
                               step={'.01'}
                               key={'facture.majorationSamediKilometrique.prix'}
                    />
                  ]}/>
        <LineSeparator/>
        <FormPart formName={'Facturation: Valorisation trajet court'}
                  formDescription={'Facturation partie Valorisation trajet court'}
                  children={[
                    <InputType label={'Quantité'}
                               inputType={'number'}
                               inputValue={form.facture.valorisationTrajetCourt.quantite}
                               name={'facture.valorisationTrajetCourt.quantite'}
                               onChangeFunction={handleChange}
                               key={'facture.valorisationTrajetCourt.quantite'}/>,
                    <InputType label={'Prix'}
                               inputType={'number'}
                               inputValue={form.facture.valorisationTrajetCourt.prix}
                               name={'facture.valorisationTrajetCourt.prix'}
                               onChangeFunction={handleChange}
                               step={'.01'}
                               key={'facture.valorisationTrajetCourt.prix'}
                    />
                  ]}/>
        <LineSeparator/>
        <FormPart formName={'Facturation: Valorisation trajet court de nuit'}
                  formDescription={'Facturation partie valorisation trajet court de nuit'}
                  children={[
                    <InputType label={'Quantité'}
                               inputType={'number'}
                               inputValue={form.facture.valorisationTrajetCourtDeNuit.quantite}
                               name={'facture.valorisationTrajetCourtDeNuit.quantite'}
                               onChangeFunction={handleChange}
                               key={'facture.valorisationTrajetCourtDeNuit.quantite'}/>,
                    <InputType label={'Prix'}
                               inputType={'number'}
                               inputValue={form.facture.valorisationTrajetCourtDeNuit.prix}
                               name={'facture.valorisationTrajetCourtDeNuit.prix'}
                               onChangeFunction={handleChange}
                               step={'.01'}
                               key={'facture.valorisationTrajetCourtDeNuit.prix'}
                    />
                  ]}/>
        <LineSeparator/>
        <FormPart formName={'Facturation: Valorisation trajet court samedi (à partir de 12h) dimanche/jours férié'}
                  formDescription={'Facturation partie tarif valorisation trajet court samedi (à partir de 12h) dimanche/jours férié'}
                  children={[
                    <InputType label={'Quantité'}
                               inputType={'number'}
                               inputValue={form.facture.valorisationTrajetCourtSamedi.quantite}
                               name={'facture.valorisationTrajetCourtSamedi.quantite'}
                               onChangeFunction={handleChange}
                               key={'facture.valorisationTrajetCourtSamedi.quantite'}/>,
                    <InputType label={'Prix'}
                               inputType={'number'}
                               inputValue={form.facture.valorisationTrajetCourtSamedi.prix}
                               name={'facture.valorisationTrajetCourtSamedi.prix'}
                               onChangeFunction={handleChange}
                               step={'.01'}
                               key={'facture.valorisationTrajetCourtSamedi.prix'}
                    />
                  ]}/>
        <LineSeparator/>
        <FormPart formName={'Facturation: Supplément'}
                  formDescription={'Facturation partie supplément'}
                  children={[
                    <InputType label={'Quantité'}
                               inputType={'number'}
                               inputValue={form.facture.supplement.quantite}
                               name={'facture.supplement.quantite'}
                               onChangeFunction={handleChange}
                               key={'facture.supplement.quantite'}/>,
                    <InputType label={'Prix'}
                               inputType={'number'}
                               inputValue={form.facture.supplement.prix}
                               name={'facture.supplement.prix'}
                               onChangeFunction={handleChange}
                               step={'.01'}
                               key={'facture.supplement.prix'}
                    />,
                    <SelectType label={'Type'}
                                selectValue={form.facture.supplement.type}
                                name={'facture.supplement.type'}
                                key={'facture.supplement.type'}
                                options={[
                                  {
                                    key: '',
                                    value: 'Pas de supplément'
                                  },
                                  {
                                    key: 'aeroport',
                                    value: 'Aéroport, port, gare'
                                  },
                                  {
                                    key: 'incubateur',
                                    value: 'Prématuré, incubateur'
                                  },
                                  {
                                    key: 'samu',
                                    value: 'SAMU-Centre 15'
                                  }
                                ]}
                                onChangeFunction={handleChange}/>
                  ]}/>
        <LineSeparator/>
        <FormPart formName={'Facturation: Abattement pour transport partagé'}
                  formDescription={'Facturation partie abattement pour transport partagé'}
                  children={[
                    <InputType label={'Quantité'}
                               inputType={'number'}
                               inputValue={form.facture.abbatemmentTransportPartage.quantite}
                               name={'facture.abbatemmentTransportPartage.quantite'}
                               onChangeFunction={handleChange}
                               key={'facture.abbatemmentTransportPartage.quantite'}/>,
                    <InputType label={'Prix'}
                               inputType={'number'}
                               inputValue={form.facture.abbatemmentTransportPartage.prix}
                               name={'facture.abbatemmentTransportPartage.prix'}
                               onChangeFunction={handleChange}
                               step={'.01'}
                               key={'facture.abbatemmentTransportPartage.prix'}
                    />,
                    <SelectType label={'Type'}
                                selectValue={form.facture.abbatemmentTransportPartage.type}
                                name={'facture.abbatemmentTransportPartage.type'}
                                key={'facture.abbatemmentTransportPartage.type'}
                                options={[
                                  {
                                    key: 0,
                                    value: 'Pas d\'autres patients'
                                  },
                                  {
                                    key: 2,
                                    value: '2 patients'
                                  },
                                  {
                                    key: 3,
                                    value: '3 patients'
                                  }
                                ]}
                                onChangeFunction={handleChange}/>
                  ]}/>
        <LineSeparator/>
        <FormPart formName={'Facturation: Péage'}
                  formDescription={'Facturation partie péage'}
                  children={[
                    <InputType label={'Quantité'}
                               inputType={'number'}
                               inputValue={form.facture.peage.quantite}
                               name={'facture.peage.quantite'}
                               onChangeFunction={handleChange}
                               key={'facture.peage.quantite'}/>,
                    <InputType label={'Prix'}
                               inputType={'number'}
                               inputValue={form.facture.peage.prix}
                               name={'facture.peage.prix'}
                               onChangeFunction={handleChange}
                               step={'.01'}
                               key={'facture.peage.prix'}
                    />
                  ]}/>
        <LineSeparator/>
        <FormPart formName={'Type de paiement:'}
                  formDescription={'Type de paiement:'}
                  children={[
                    <SelectType label={'Type de paiement:'}
                                selectValue={form.frais}
                                name={'frais'}
                                key={'frais'}
                                options={[
                                  {
                                    key: 'totale',
                                    value: 'Totale'
                                  }, {
                                    key: 'partielle',
                                    value: 'Partielle'
                                  }, {
                                    key: 'direct',
                                    value: 'Direct'
                                  }
                                ]}
                                onChangeFunction={handleChange}/>
                  ]}
        />
      </div>
      <LineSeparator/>
      <div className={'max-w-screen-xl flex justify-items-center mx-auto mb-4'}>
        <button
          className={'mx-auto content-center text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mx-auto flex-initial'}
          onClick={downloadFileFromForm}
        >
          Télécharger la facture
        </button>
      </div>
    </Fragment>

  )
}

export default Form;
