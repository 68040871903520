import React from "react";

export interface IErrorMsgProps {
  errors: [string]
}

export const ErrorMsg = ({errors}: IErrorMsgProps) => (
  <div>
    {errors.map((error: string) =>
      <div key={error}>{error}</div>
    )}
  </div>
)
