export function downloadFromUrl (blobData: any, filename: string) {
  let blob = new Blob([blobData]);

  let fileUrl = window.URL.createObjectURL(blob);
  //window.open(csvURL);
  // then commenting out the window.open & replacing
  // with this allowed a file name to be passed out
  let tempLink = document.createElement('a');
  tempLink.href = fileUrl;
  tempLink.setAttribute('download', filename);
  tempLink.setAttribute('target', '_blank')
  tempLink.click();
  tempLink.remove();
}
