import React from "react";

export interface ISelectTypeProps {
  label: string;
  selectValue: string;
  name: string;

  options: Array<{
    key: string | number,
    value: string
  }>
  onChangeFunction(event: any): Promise<void>;
}

export const SelectType = (props: ISelectTypeProps) => {
  return (
    <div key={props.name} className="col-span-6 sm:col-span-3">
      <label htmlFor="country" className="block text-sm font-medium text-gray-700">
        {props.label}
      </label>
      <select
        id="country"
        value={props.selectValue} name={props.name}
        onChange={props.onChangeFunction}
        autoComplete="country-name"
        className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
      >
        {props.options.map((option) => (
          <option key={option.key} value={option.key}>{option.value}</option>
        ))}
      </select>
    </div>
  )
}
