import React, {Fragment} from "react";

interface IFormPartProps {
  formName: string;
  formDescription: string;

  children: Array<any>;
}

export const FormPart = (props: IFormPartProps) => {
  return (
    <div className="mt-10 sm:mt-0">
      <div className="md:grid md:grid-cols-3 md:gap-6">
        <div className="md:col-span-1">
          <div className="px-4 sm:px-0">
            <h3 className="text-lg font-medium leading-6 text-gray-900">{props.formName}</h3>
            <p className="mt-1 text-sm text-gray-600">{props.formDescription}</p>
          </div>
        </div>
        <div className="mt-5 md:col-span-2 md:mt-0">
          <form action="#" method="POST">
            <div className="overflow-hidden shadow sm:rounded-md">
              <div className="bg-white px-4 py-5 sm:p-6">
                <div className="grid grid-cols-6 gap-6">
                  {props.children.map((child, key) =>(
                    <Fragment key={key}>
                      {child}
                    </Fragment>
                    )
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
