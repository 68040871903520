import React from "react";

export interface IInputTypeProps {
  label: string;
  inputType: 'date' | 'number' | 'text',
  inputValue: any;
  name: string;
  onChangeFunction(event:any) : Promise<void>;
  step?: string;
}

export const InputType = (props: IInputTypeProps) => {
  return (
    <div key={props.name} className="col-span-6 sm:col-span-3">
      <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
        {props.label}
      </label>

      <input
        type={props.inputType}
        id="first-name"
        autoComplete="given-name"
        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        value={props.inputValue}
        name={props.name} onChange={props.onChangeFunction}
        step={props.step}
      />
    </div>
  )
}
