import React from "react";

export interface ICheckboxTypeProps {
  checked: boolean,
  name: string,

  onChangeFunction(event: any): Promise<void>

  label: string,
}

export const CheckboxType = (props: ICheckboxTypeProps) => {
  return (
    <div key={props.name} className="col-span-6 sm:col-span-3">
      <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
        {props.label}
      </label>
      <input
        id="first-name"
        autoComplete="given-name"
        className="mt-1 block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm center"
        type="checkbox" checked={props.checked} name={props.name}
        onChange={props.onChangeFunction}
      />
    </div>
  )
}
